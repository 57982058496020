
  import Vue from "vue";

  export default Vue.extend({
    name: "pwa-cross-selling-banner",
    methods: {
      async onClickButton() {
        this.openLink('https://www.winnder.com/signup');
      },
    }
  });
